html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

hr {
  width: 100%;
}

.navContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.leftRail {
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  background-color: var(--spectrum-global-color-gray-200);
  display: none;
  overflow-x: auto;
  z-index: 1;
}

.leftRailOpen {
  position: absolute;
  display: flex;
  height: calc(100% - 50px);
}

.content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;

  overflow: scroll;
}

.navigation {
  height: 50px;
  width: 100%;
  background-color: var(--spectrum-global-color-gray-300);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 5px 10px var(--spectrum-global-color-gray-100);
  z-index: 2;
}

.navContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 15px;
}

.navDrawerButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 15px;
}

.navTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 25px;
}

@media only screen and (min-width: 1200px) {
  .navDrawerButton {
    display: none;
  }

  .leftRail {
    display: flex;
  }

  .leftRailOpen {
    position: relative;
    display: flex;
    height: 100%;
  }
}

.username {
  margin-right: 16px;
}

.section {
  min-width: calc(50% - 8px) !important;
}

@media only screen and (max-width: 533px) {
  .section {
    min-width: 100% !important;
  }
}
